import React, { useState, useEffect } from 'react';
import ProductCard, { CardType } from '../ProductCard';
import { ProductModel } from '@/models/Product.model';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import styles from '../../styles/slickProductCard.module.css';
import ProductCardBundle from '../ProductDetailPage/ProductCardBundle';
import SliderLayout from './SliderLayout';

interface Props {
  products: ProductModel[] | undefined;
  title?: string;
  isBundle?: boolean;
  isBundleImage?: boolean;
  bgColor?: string | null | undefined;
  slidesToShow: number; // xl or more and default
  centerMode?: boolean;
  dots?: boolean;
  arrows?: boolean;
  autoplay?: boolean;
  lg?: { slidesToShow: number; slidesPerRow?: number };
  md?: { slidesToShow: number; slidesPerRow?: number };
  sm?: { slidesToShow: number; slidesPerRow?: number };
  xs?: { slidesToShow: number; slidesPerRow?: number };
  infinite?: boolean;
  isShort?: boolean;
  isBundleSoft?: boolean;
  // eslint-disable-next-line no-unused-vars
  callBackProduct?: (selectProduct: { sku: string; qty: number }[]) => void;
  isShowAddToCart?: boolean;
  isFlashSale?: boolean;
  listName?: string;
  isShortWithQty?: boolean;
  slidesPerRow?: number;
}

export default function SlickProductCard({
  products,
  title,
  isBundle,
  isBundleImage,
  bgColor,
  slidesToShow,
  centerMode,
  dots,
  arrows,
  autoplay,
  lg,
  md,
  sm,
  xs,
  infinite = true,
  isShort,
  isBundleSoft,
  callBackProduct,
  isShowAddToCart,
  isFlashSale,
  listName,
  isShortWithQty,
  slidesPerRow,
}: Props) {
  const [selectedProduct, setSelectedProduct] = useState<
    { sku: string; qty: number }[]
  >([]);
  const newProducts: ProductModel[] | undefined = isBundleSoft
    ? products && products.length > 1
      ? products?.slice(1)
      : []
    : products;

  useEffect(() => {
    if (isBundleSoft) {
      let tempSelectedProduct =
        products
          ?.map((el) => {
            return { sku: el.sku, qty: el.minQty ?? 1 };
          })
          .slice(0, 2) ?? [];
      setSelectedProduct(tempSelectedProduct);
      callBackProduct?.(tempSelectedProduct);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isBundleSoft]);

  const handleSelectedSku = (sku: string, qty: number) => {
    let tempSelectedProduct = selectedProduct;
    if (selectedProduct && selectedProduct?.find((e) => e.sku === sku)) {
      tempSelectedProduct = selectedProduct.filter((el) => el.sku !== sku);
      setSelectedProduct(tempSelectedProduct);
    } else {
      tempSelectedProduct = [...selectedProduct, { sku, qty }];
      setSelectedProduct(tempSelectedProduct);
    }

    callBackProduct?.(tempSelectedProduct);
  };
  const handleChangeQuantity = (sku: string, qty: number) => {
    let tempSelectedProduct = selectedProduct;
    if (selectedProduct && selectedProduct?.find((e) => e.sku === sku)) {
      tempSelectedProduct = selectedProduct.map((item) =>
        item.sku === sku ? { sku, qty } : item,
      );
      setSelectedProduct(tempSelectedProduct);
      callBackProduct?.(tempSelectedProduct);
    }
  };

  return (
    <>
      {newProducts && newProducts.length > 0 ? (
        <>
          {title ? (
            <h2 className="text-2xl font-bold text-center text-primary">
              {title}
            </h2>
          ) : (
            ''
          )}
          <div style={{ backgroundColor: bgColor ? bgColor : '' }}>
            <SliderLayout
              infinite={infinite}
              centerMode={centerMode}
              dots={dots}
              arrows={arrows}
              autoplay={autoplay}
              slidesToShow={slidesToShow}
              xs={xs}
              sm={sm}
              md={md}
              lg={lg}
              slidesPerRow={slidesPerRow}
            >
              {newProducts.map((product, index) => (
                <div
                  key={`${product.sku}${index}`}
                  className={`${
                    isBundle ? styles.navProdBundle : styles.navProd
                  } relative`}
                >
                  {isBundle ? (
                    <>
                      <ProductCardBundle
                        product={product}
                        isBundleSoft={isBundleSoft}
                        isBundleImage={isBundleImage}
                        callBackSku={handleSelectedSku}
                        callBackQty={handleChangeQuantity}
                        isSelectedBundleSoft={
                          selectedProduct.find((e) => e.sku === product.sku)
                            ? true
                            : false
                        }
                      />
                      {index + 1 == newProducts?.length || isBundleSoft ? (
                        <></>
                      ) : (
                        <span
                          className={
                            isBundleImage
                              ? `${styles.plusBundleImage} absolute`
                              : `${styles.plusBundle} absolute`
                          }
                        >
                          +
                        </span>
                      )}
                    </>
                  ) : (
                    <ProductCard
                      listName={
                        listName ? listName : `slide_${title ?? 'product'}`
                      }
                      product={product}
                      type={CardType.slide}
                      isShort={isShort}
                      isShowAddToCart={isShowAddToCart}
                      isFlashSale={isFlashSale}
                      isShortWithQty={isShortWithQty}
                    />
                  )}
                </div>
              ))}
            </SliderLayout>
          </div>
        </>
      ) : (
        <></>
      )}
    </>
  );
}
