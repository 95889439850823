import React, { useState } from 'react';
import AppLink from '@/components/Layout/handler/AppLink';
import { ProductModel } from '@/models/Product.model';
import ProductImageBase64 from '../common/ProductImageBase64';
import BundleQnt from './BundleQnt';
import ProductCounter from '@/components/common/ProductCounter';
import { Grid } from '@mui/material';
import { useTranslation } from 'next-i18next';
import BadgeLayout from '../ProductCard/BadgeLayout';

interface Props {
  product: ProductModel;
  isBundleImage?: boolean;
  isMain?: boolean;
  isSelectedBundleSoft?: boolean;
  isBundleSoft?: boolean;
  // eslint-disable-next-line no-unused-vars
  callBackSku?: (sku: string, qty: number) => void;
  // eslint-disable-next-line no-unused-vars
  callBackQty?: (sku: string, qty: number) => void;
}

export default function ProductCardBundle({
  product,
  isBundleImage,
  isMain,
  isSelectedBundleSoft,
  isBundleSoft,
  callBackSku,
  callBackQty,
}: Props) {
  const { t } = useTranslation('');
  const [parentCount, setParentCount] = useState<number>(
    isBundleSoft ? product?.minQty ?? 1 : product?.qntBundle ?? 1,
  );

  return (
    <AppLink
      href={isBundleSoft === true ? undefined : `/product/${product.slugname}`}
      onClick={
        isBundleSoft === true &&
        isMain !== true &&
        (product?.stockAvail ?? 0) > 0
          ? () => {
              callBackSku?.(product.sku, parentCount);
            }
          : undefined
      }
    >
      <div
        className={`px-1 ${
          isBundleSoft === true ? 'md:px-2' : 'md:px-4'
        }  pt-1 md:pt-2 pb-1 bg-white hover:shadow-md relative ${
          isBundleSoft && (isSelectedBundleSoft || isMain)
            ? 'border-2 rounded border-primary'
            : ''
        } `}
      >
        {isBundleSoft === true && isMain !== true && (
          <div className="absolute z-10" style={{ top: 5, right: 6 }}>
            {isSelectedBundleSoft ? (
              <i className="bi bi-check-circle-fill text-primary" />
            ) : (
              <i className="bi bi-circle" />
            )}
          </div>
        )}

        <div className={`${isBundleSoft && isMain ? 'flex lg:block' : ''}`}>
          <div className="relative">
            <ProductImageBase64
              pimImage={product.pimImage}
              base64={product.base64}
              alt={product.name}
            />
            {isBundleSoft !== true && <BundleQnt qnt={product.qntBundle} />}
          </div>
          <div>
            {isBundleImage ? (
              <></>
            ) : (
              <>
                {!isBundleSoft && (
                  <BadgeLayout product={product} isHideShowStock={true} />
                )}

                <div className={`${isBundleSoft ? '' : 'hidden lg:block'}`}>
                  <div className="leading-3 h-4 overflow-hidden mb-0.5">
                    <span className="font-semibold text-lg leading-5">
                      {product.brand}
                    </span>
                  </div>
                  <div className="leading-3 h-9 md:h-8 overflow-hidden mb-0.5">
                    <span className="text-base leading-3 md:leading-4 line-clamp-2">
                      {product.name}
                    </span>
                  </div>
                </div>
              </>
            )}
            <div
              className={`${
                isBundleImage || isBundleSoft ? '' : 'hidden lg:block'
              }`}
            >
              {isBundleImage ? (
                <></>
              ) : (
                <>
                  <div className="leading-3 h-3 text-sm text-grayDark">
                    {`${t('common:sku')}: ${product.sku}`}
                  </div>
                  <div className="h-[0.9rem]"></div>
                  <div className="flex items-center">
                    <div className="w-full">
                      <div className="flex justify-between items-center">
                        <div className="text-redPrice font-bold text-lg leading-3">
                          ฿
                        </div>
                        <div className="text-grayDark text-sm leading-3 line-through">
                          {product.discount
                            ? `฿${product.discount}`
                            : undefined}
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className={`leading-3 mt-1 text-center`}>
                {isBundleImage && <span className="text-redPrice">฿ </span>}
                <span
                  className={`text-redPrice font-bold ${
                    isBundleImage ? 'text-base' : 'text-2xl'
                  } font-price`}
                >{`${product.price}`}</span>
              </div>
              {isBundleImage ? (
                <></>
              ) : (
                <div
                  className={`flex ${
                    isBundleSoft && isMain !== true
                      ? 'justify-center'
                      : 'justify-between'
                  } items-center h-1 sm:h-4 mb-1 sm:mb-0`}
                >
                  {isBundleSoft && isMain !== true ? (
                    <span className="text-primary font-bold">
                      {t('product:discount')} {product.bundleReduce}.-
                    </span>
                  ) : (
                    <span className="text-xxs text-[#0e0c0c]">
                      {t('product:pricesmayvaryfrombranch')}
                    </span>
                  )}
                  <span className="text-xxs text-grayDark inline">
                    /{product.prUname}
                  </span>
                </div>
              )}

              {isBundleSoft && (
                <Grid container spacing={2}>
                  {isMain !== true && (
                    <Grid
                      item
                      xs={12}
                      lg={5}
                      className="text-center lg:text-left"
                    >
                      <AppLink href={`/product/${product.slugname}`}>
                        <i className="bi bi-search text-xs" />
                        <span className="ml-2 underline">
                          {t('product:seemore')}
                        </span>
                      </AppLink>
                    </Grid>
                  )}
                  <Grid item xs={12} lg={7} className="mx-auto">
                    {(product?.stockAvail ?? 0) > 0 ? (
                      <ProductCounter
                        currentValue={parentCount}
                        minQty={product.minQty ?? 1}
                        maxQty={product.qntBundle ?? 1}
                        onChange={(newCount: number) => {
                          setParentCount(newCount);
                          if (isSelectedBundleSoft) {
                            callBackQty?.(product.sku, newCount ?? 1);
                          }
                        }}
                      />
                    ) : (
                      <div className="bg-grayLight1 text-grayDark rounded text-center">
                        {t('common:outofstock')}
                      </div>
                    )}
                    {product.minQty && product.minQty > 1 && (
                      <div className="text-center text-xs text-primary">
                        <span>
                          *{t('common:minimumorderamount')} {product.minQty}{' '}
                          {t('common:unit')}
                        </span>
                      </div>
                    )}
                  </Grid>
                </Grid>
              )}
            </div>
          </div>
        </div>
      </div>
    </AppLink>
  );
}
